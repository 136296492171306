import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import { Header } from '../components/header';

import { main } from './index.module.css';
import * as styles from './about.module.css';
import audeImage from '../images/aude.png';
import papillonImage from '../images/logo_papillon_transparent.png';

const About = ({ data }) => {
  return (
    <div className={main}>
      <div>
        <Helmet>
          <meta name="description" content="Je m’appelle Aude FASANI et je suis accompagnante Bien-Être" />
          <title>{data.site.siteMetadata.title}</title>
        </Helmet>
        <Header></Header>
      </div>

      <div className={styles.mainContent}>
        <h1>A propos de moi</h1>
        <div>
          <img src={audeImage} alt="Aude Fasani" title="Aude Fasani" className={styles.mainImage} />
          <p>« Je m’appelle Aude FASANI et je suis accompagnante Bien-Être.</p>
          <p>
            J’ai travaillé pendant 20 ans dans la petite enfance mais le besoin qui germait depuis plusieurs années de
            changer pour un mieux-être et apporter du bien-être m’a poussé à me reconvertir.{' '}
          </p>

          <p>
            Après m’être formée à la relaxation, à l’expression corporelle et aux massages, « Aude Bien-Être » est née
            au début de l’année 2016.
          </p>
          <p>
            {' '}
            Ma vision du métier est d’apporter dans votre vie, un moment de détente rien que pour vous. Je m’investis
            pour répondre au mieux à ce dont vous avez besoin quand vous venez me rencontrer. Je personnalise votre soin
            en alliant les différentes techniques que j’ai pu apprendre. Un climat de confiance doit s’installé pour que
            votre moment soit vraiment bénéfique et vous amène au lâcher prise résultat de la détente.{' '}
          </p>
          <p>
            Le matériel est adapté au soin prodigué, par exemple une table de massage chauffante car la détente amène
            souvent le corps à se refroidir, une chaise ergonomique, de la musique, etc…. Je propose aussi des bons
            cadeaux pour faire plaisir ou vous offrir un moment de détente. Pour tout renseignement ou prise de
            rendez-vous n’hésitez pas à me contacter par téléphone ou mail. »
          </p>
        </div>
        <p className={styles.logoImage}>
          <img src={papillonImage} alt="logo aude-bien-etre" />
        </p>
      </div>
    </div>
  );
};

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
export default About;
